/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_NotificationPusherSettings {
    .mx_NotificationPusherSettings_description {
        color: $primary-content;
    }

    .mx_NotificationPusherSettings_detail {
        margin-top: -4px;
        margin-bottom: 12px;
    }
}
