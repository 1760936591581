/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_HiddenBody {
    white-space: pre-wrap;
    color: $muted-fg-color;
    vertical-align: middle;

    padding-left: 20px;
    position: relative;

    &::before {
        height: 14px;
        width: 14px;
        background-color: $muted-fg-color;
        mask-image: url("$(res)/img/element-icons/hide.svg");

        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        content: "";
        position: absolute;
        top: 1px;
        left: 0;
    }
}
