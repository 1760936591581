/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_VerifyEMailDialog {
    height: auto;
    top: 300px;

    .mx_Dialog {
        color: $primary-content;
        font: var(--cpd-font-body-md-regular);
        padding: $spacing-24 $spacing-24 $spacing-16;
        text-align: center;
        width: 485px;

        h1 {
            font-size: $font-24px;
            font-weight: var(--cpd-font-weight-semibold);
        }

        .mx_VerifyEMailDialog_text-light {
            color: $secondary-content;
            line-height: 20px;
        }

        .mx_AuthBody_did-not-receive {
            justify-content: center;
            margin-bottom: $spacing-8;
        }

        .mx_Dialog_cancelButton {
            right: 10px;
        }
    }
}
