/*
Copyright 2024 New Vector Ltd.
Copyright 2020, 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

progress.mx_ProgressBar {
    height: 6px;
    width: 60px;
    overflow: hidden;
    appearance: none;
    border: var(--cpd-border-width-1) solid var(--cpd-color-gray-400);

    @mixin ProgressBarBorderRadius 6px;
    @mixin ProgressBarColour var(--cpd-color-icon-accent-tertiary);
    @mixin ProgressBarBgColour $progressbar-bg-color;
    &::-webkit-progress-value {
        transition: width 1s;
    }
}
