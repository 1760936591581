/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_PollCreateDialog {
    .mx_PollCreateDialog_busy {
        position: absolute;
        inset: 0;
        background-color: $overlay-background;
        z-index: 1;
    }

    h2 {
        font-weight: var(--cpd-font-weight-semibold);
        font-size: $font-15px;
        line-height: $font-24px;
        margin-top: 0;
        margin-bottom: 8px;

        &:nth-child(n + 2) {
            margin-top: 20px;
        }
    }

    p {
        color: $secondary-content;
    }

    .mx_PollCreateDialog_option {
        display: flex;
        align-items: center;
        margin-top: 11px;
        margin-bottom: 16px; /* 11px from the top will collapse, so this creates a 16px gap between options */

        .mx_Field {
            flex: 1;
            margin: 0;
        }

        .mx_PollCreateDialog_removeOption {
            margin-left: 12px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $quinary-content;
            cursor: pointer;
            position: relative;

            &::before {
                content: "";
                mask: url("@vector-im/compound-design-tokens/icons/close.svg");
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: 16px;
                width: inherit;
                height: inherit;
                position: absolute;
                background-color: $secondary-content;
            }
        }
    }

    .mx_PollCreateDialog_addOption {
        padding: 0;
        margin-bottom: 40px; /* arbitrary to create scrollable area under the poll */
    }

    .mx_AccessibleButton_disabled {
        opacity: 0.4;
    }
}
