/*
Copyright 2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_MobileRegister_body {
    padding: 32px;
    height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;
}
