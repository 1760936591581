/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_PollListItemEnded {
    width: 100%;
}

.mx_PollListItemEnded_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $primary-content;
    cursor: pointer;
}

.mx_PollListItemEnded_title {
    display: grid;
    justify-content: left;
    align-items: center;
    grid-gap: $spacing-8;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: auto;
}

.mx_PollListItemEnded_icon {
    height: 14px;
    width: 14px;
    color: $quaternary-content;
    padding-left: $spacing-8;
}

.mx_PollListItemEnded_date {
    font-size: $font-12px;
    color: $secondary-content;
}

.mx_PollListItemEnded_question {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mx_PollListItemEnded_answers {
    display: grid;
    grid-gap: $spacing-8;
    margin-top: $spacing-12;
}

.mx_PollListItemEnded_voteCount {
    /* 6px to match PollOption padding */
    margin: $spacing-8 0 0 6px;
}
