/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_OwnBeaconStatus_button {
    margin-left: $spacing-8;
}

.mx_EventTile[data-layout="bubble"] .mx_OwnBeaconStatus_button {
    /* align to top to make room for timestamp */
    /* in bubble view */
    align-self: start;
}

.mx_OwnBeaconStatus_destructiveButton {
    /* override button link_inline styles */
    color: $alert !important;
    font-weight: var(--cpd-font-weight-semibold) !important;
}
