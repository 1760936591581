/*
* Copyright 2024 New Vector Ltd.
* Copyright 2024 The Matrix.org Foundation C.I.C.
*
* SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
* Please see LICENSE files in the repository root for full details.
 */

.mx_EventPreview {
    font: var(--cpd-font-body-sm-regular);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .mx_EventPreview_prefix {
        font: var(--cpd-font-body-sm-semibold);
    }
}
