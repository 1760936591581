/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.
Copyright 2019 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_VerificationShowSas_decimalSas {
    text-align: center;
    font-weight: bold;
    padding-left: 3px;
    padding-right: 3px;
}

.mx_VerificationShowSas_decimalSas span {
    margin-left: 5px;
    margin-right: 5px;
}

.mx_VerificationShowSas_emojiSas {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 25px 0;
}

.mx_VerificationShowSas_emojiSas_block {
    display: inline-block;
    margin-bottom: 16px;
    position: relative;
    width: 52px;
}

.mx_Dialog .mx_VerificationShowSas_emojiSas_block,
.mx_AuthPage_modal .mx_VerificationShowSas_emojiSas_block {
    width: 60px;
}

.mx_VerificationShowSas_emojiSas_emoji {
    font-size: $font-32px;
    /* Use the Twemoji font for consistency with other clients */
    font-family: Twemoji, var(--cpd-font-family-sans);
}

.mx_VerificationShowSas_emojiSas_label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: $font-12px;
}

.mx_VerificationShowSas_emojiSas_break {
    flex-basis: 100%;
}

.mx_VerificationShowSas_buttonRow {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 9px;
}
