/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_FilterTabGroup {
    color: $primary-content;
    label {
        margin-right: $spacing-12;
        cursor: pointer;
        span {
            display: inline-block;
            line-height: $font-24px;
        }
    }
    input[type="radio"] {
        appearance: none;
        margin: 0;
        padding: 0;

        &:focus,
        &:hover {
            & + span {
                color: $secondary-content;
            }
        }

        &:checked + span {
            color: $accent;
            font-weight: var(--cpd-font-weight-semibold);
            /* underline */
            box-shadow: 0 1.5px 0 0 currentColor;
        }
    }
}
