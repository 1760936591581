/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_DecoratedRoomAvatar,
.mx_ExtraTile {
    position: relative;
    contain: content;
    line-height: 0;

    &.mx_DecoratedRoomAvatar_cutout .mx_BaseAvatar {
        mask-image: url("$(res)/img/element-icons/roomlist/decorated-avatar-mask.svg");
        mask-position: center;
        mask-size: contain;
        mask-repeat: no-repeat;
    }

    .mx_DecoratedRoomAvatar_icon {
        position: absolute;
        /* the following percentage based sizings are to match the scalable svg mask for the cutout */
        bottom: 6.25%; /* 2px for a 32x32 avatar */
        right: 6.25%;
        width: 25%; /* 8px for a 32x32 avatar */
        height: 25%;
        border-radius: 50%;
    }

    .mx_DecoratedRoomAvatar_icon::before {
        content: "";
        width: 100%;
        height: 100%;
        right: 0;
        position: absolute;
        border-radius: 8px;
    }

    .mx_DecoratedRoomAvatar_icon_globe::before {
        mask-position: center;
        mask-size: contain;
        mask-repeat: no-repeat;
        background: $secondary-content;
        mask-image: url("@vector-im/compound-design-tokens/icons/public.svg");
    }

    .mx_DecoratedRoomAvatar_icon_offline::before {
        background-color: $presence-offline;
    }

    .mx_DecoratedRoomAvatar_icon_online::before {
        background-color: $accent;
    }

    .mx_DecoratedRoomAvatar_icon_away::before {
        background-color: $presence-away;
    }

    .mx_DecoratedRoomAvatar_icon_busy::before {
        background-color: $presence-busy;
    }

    .mx_NotificationBadge,
    .mx_RoomTile_badgeContainer {
        position: absolute;
        top: 0;
        right: 0;
        height: 18px;
        width: 18px;
    }
}
