/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_BeaconStatusTooltip {
    position: absolute;
    top: 42px;
    max-width: 150px;
    height: 38px;
    box-sizing: content-box;
    padding-top: $spacing-8;
}

.mx_BeaconStatusTooltip_inner {
    position: relative;
    height: 100%;
    border-radius: 4px;
    background: $menu-bg-color;
    box-shadow: 4px 4px 12px 0 $menu-box-shadow-color;
}
