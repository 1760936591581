/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_AuthHeaderLogo {
    margin-top: 15px;
    flex: 1;
    padding: 0 25px;
}

.mx_AuthHeaderLogo img {
    width: 100%;
}

@media only screen and (max-width: 480px) {
    .mx_AuthHeaderLogo {
        display: none;
    }
}
