/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_DecryptionFailureBody {
    color: $secondary-content;
    font-style: italic;
}

/* Formatting for errors due to sender trust requirement failures */
.mx_DecryptionFailureSenderTrustRequirement > span {
    /* some space between the (/) icon and text */
    display: inline-flex;
    gap: var(--cpd-space-1x);

    /* Center vertically */
    align-items: center;
}
