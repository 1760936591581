/*
* Copyright 2024 New Vector Ltd.
* Copyright 2024 The Matrix.org Foundation C.I.C.
*
* SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
* Please see LICENSE files in the repository root for full details.
 */

.mx_ThreadsActivityCentre_container {
    display: flex;
}

.mx_ThreadsActivityCentreButton {
    border-radius: 8px;
    margin: 18px auto auto auto;

    &.expanded {
        /**
         * override compound default background color when hovered
         * should disappear when the space panel will be migrated to compound
         */
        background-color: transparent !important;

        /* align with settings icon */
        margin-left: 21px;

        /**
         * modify internal css of the compound component
         * dirty but we need to add the `Threads` label into the indicator icon button
         **/
        & > div {
            display: flex;
            align-items: center;
        }

        & .mx_ThreadsActivityCentreButton_Icon {
            /* align with settings label */
            margin-right: 14px;
            /* required to set the icon width when into a flex container */
            min-width: 24px;
        }

        & .mx_ThreadsActivityCentreButton_Text {
            color: $secondary-content;
        }
    }

    &:not(.expanded) {
        &:hover,
        &:hover .mx_ThreadsActivityCentreButton_Icon {
            background-color: $quaternary-content;
            color: $primary-content;
        }
    }

    & .mx_ThreadsActivityCentreButton_Icon {
        color: $secondary-content;
    }
}

.mx_ThreadsActivityCentre_rows {
    overflow-y: scroll;
    /* Let some space at the top and the bottom of the pop-up */
    max-height: calc(100vh - 200px);

    .mx_ThreadsActivityCentreRow {
        height: 48px;

        /* Make the label of the MenuItem stay on one line and truncate with ellipsis if needed */
        & > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            /* Arbitrary size, keep the TAC as the wanted width */
            width: 202px;
        }
    }
}

.mx_ThreadsActivityCentre_emptyCaption {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 13px;
}
