/*
Copyright 2024 New Vector Ltd.
Copyright 2023 Suguru Hirahara

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_AppWarning {
    font-size: $font-16px;
    justify-content: center;

    h4 {
        margin: 0;
        padding: 0;
    }
}
