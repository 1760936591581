/*
Copyright 2024 New Vector Ltd.
Copyright 2020, 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

span.mx_MVideoBody {
    overflow: hidden;

    .mx_MVideoBody_container {
        border-radius: var(--MBody-border-radius);
        overflow: hidden;

        video {
            height: 100%;
            width: 100%;
        }
    }
}
