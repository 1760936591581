/*
Copyright 2024 New Vector Ltd.
Copyright 2021 Šimon Brandner <simon.bra.ag@gmail.com>

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_ExistingSpellCheckLanguage {
    display: flex;
    align-items: center;
}

.mx_ExistingSpellCheckLanguage_language {
    flex: 1;
    margin-right: 10px;
}

.mx_GeneralUserSettingsTab_spellCheckLanguageInput {
    margin-bottom: $spacing-8;
}
