/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_CallView {
    flex-grow: 1;
    min-height: 0;

    display: flex;
    flex-direction: column;

    background-color: $header-panel-bg-color;

    .mx_AppTile {
        width: auto;
        height: 100%;
        border: none;
        border-radius: inherit;
        background-color: $call-background;
    }

    /* While the lobby is shown, the widget needs to stay loaded but hidden in the background */
    .mx_CallView_lobby ~ .mx_AppTile {
        display: none;
    }

    .mx_CallView_lobby {
        min-height: 0;
        flex-grow: 1;
        padding: $spacing-12;
        color: $call-primary-content;
        background-color: $call-background;

        --facepile-background: $call-background;
        border-radius: 8px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: $spacing-32;

        .mx_FacePile {
            width: fit-content;
            margin: $spacing-8 auto 0;
        }

        .mx_CallView_preview {
            position: relative;
            width: 100%;
            max-width: 800px;
            aspect-ratio: 1.5;
            background-color: $call-system;

            border-radius: 20px;
            overflow: hidden;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .mx_BaseAvatar {
                margin: $spacing-20;

                /* Override the explicit dimensions on the element so that this gets sized responsively */
                width: unset !important;
                height: unset !important;
                min-width: 0;
                min-height: 0;
                flex: 0 1 200px;
            }

            video {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                transform: scaleX(-1); /* flip the image */
                background-color: black;
            }

            .mx_CallView_controls {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;

                background-color: $info-plinth-fg-color;

                display: flex;
                justify-content: center;
                gap: $spacing-24;

                .mx_CallView_deviceButtonWrapper {
                    position: relative;
                    margin: 6px 0 10px;

                    .mx_CallView_deviceButton {
                        $size: 50px;

                        width: $size;
                        height: $size;

                        background-color: $call-system;
                        border-radius: calc($size / 2);

                        &::before {
                            content: "";
                            display: inline-block;
                            mask-repeat: no-repeat;
                            mask-size: 20px;
                            mask-position: center;
                            background-color: $call-primary-content;
                            height: 100%;
                            width: 100%;
                        }

                        &.mx_CallView_deviceButton_audio::before {
                            mask-image: url("@vector-im/compound-design-tokens/icons/mic-on-solid.svg");
                            mask-size: 14px;
                        }

                        &.mx_CallView_deviceButton_video::before {
                            mask-image: url("@vector-im/compound-design-tokens/icons/video-call-solid.svg");
                        }
                    }

                    .mx_CallView_deviceListButton {
                        $size: 15px;

                        position: absolute;
                        bottom: 0;
                        right: -2.5px;
                        width: $size;
                        height: $size;

                        background-color: $call-system;
                        border-radius: calc($size / 2);

                        &::before {
                            content: "";
                            display: inline-block;
                            mask-image: url("@vector-im/compound-design-tokens/icons/chevron-down.svg");
                            mask-size: 20px;
                            mask-position: center;
                            background-color: $call-primary-content;
                            height: 100%;
                            width: 100%;
                        }
                    }

                    &.mx_CallView_deviceButtonWrapper_muted {
                        .mx_CallView_deviceButton,
                        .mx_CallView_deviceListButton {
                            background-color: $call-primary-content;

                            &::before {
                                background-color: $call-system;
                            }
                        }

                        .mx_CallView_deviceButton {
                            &.mx_CallView_deviceButton_audio::before {
                                mask-image: url("@vector-im/compound-design-tokens/icons/mic-off-solid.svg");
                                mask-size: 18px;
                            }

                            &.mx_CallView_deviceButton_video::before {
                                mask-image: url("@vector-im/compound-design-tokens/icons/video-call-off-solid.svg");
                            }
                        }
                    }
                }
            }
        }

        .mx_CallView_connectButton {
            padding-left: 50px;
            padding-right: 50px;
        }
    }
}
