/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

/*
 * Compound icon

 * {@link https://www.figma.com/file/X4XTH9iS2KGJ2wFKDqkyed}
 */

.mx_Icon {
    box-sizing: border-box;
}

.mx_Icon_accent {
    color: $accent;
}

.mx_Icon_bg-accent-light {
    background-color: $accent-300;
}

.mx_Icon_alert {
    color: $alert;
}

.mx_Icon_circle-40 {
    border-radius: 20px;
    flex: 0 0 40px;
    height: 40px;
    padding: 0 12px;
    width: 40px;
}

.mx_Icon_8 {
    flex: 0 0 8px;
    height: 8px;
    width: 8px;
}

.mx_Icon_10 {
    flex: 0 0 10px;
    height: 10px;
    width: 10px;
}

.mx_Icon_12 {
    flex: 0 0 12px;
    height: 12px;
    width: 12px;
}

.mx_Icon_16 {
    flex: 0 0 16px;
    height: 16px;
    width: 16px;
}

.mx_Icon_24 {
    flex: 0 0 24px;
    height: 24px;
    width: 24px;
}

.mx_Icon_32 {
    flex: 0 0 32px;
    height: 32px;
    width: 32px;
}
