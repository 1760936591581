/*
Copyright 2024 New Vector Ltd.
Copyright 2024 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_ExtensionsCard {
    --cpd-separator-inset: var(--cpd-space-4x);
    --cpd-separator-spacing: var(--cpd-space-4x);

    .mx_AutoHideScrollbar {
        padding: 0 var(--cpd-space-4x);
        margin-top: var(--cpd-space-3x);
        box-sizing: border-box;

        /* Styling for the "Add extensions" button */
        & > button {
            width: 100%;
        }
    }

    .mx_ExtensionsCard_container {
        text-align: center;
        margin: $spacing-20 var(--cpd-space-4x) 0;
    }

    .mx_ExtensionsCard_Button {
        /* this button is special so we have to override some of the original styling */
        /* as we will be applying it in its children */
        padding: 0;
        height: auto;
        color: $tertiary-content;
        position: relative;

        .mx_WidgetAvatar {
            flex-shrink: 0;
        }

        .mx_ExtensionsCard_icon_app {
            padding: var(--cpd-space-2x) var(--cpd-space-12x) var(--cpd-space-2x) var(--cpd-space-3x);
            text-overflow: ellipsis;
            overflow: hidden;
            display: flex;
            align-items: center;

            p {
                margin: 0 var(--cpd-space-3x);
                color: $primary-content;
            }
        }

        .mx_ExtensionsCard_app_pinToggle,
        .mx_ExtensionsCard_app_options {
            position: absolute;
            top: 0;
            height: 100%; /* to give bigger interactive zone */
            width: 24px;
            padding: var(--cpd-space-3x) var(--cpd-space-1x);
            box-sizing: border-box;
            min-width: 24px; /* prevent flexbox crushing */

            &:hover {
                &::after {
                    content: "";
                    position: absolute;
                    height: 24px;
                    width: 24px;
                    top: var(--cpd-space-2x); /* equal to padding-top of parent */
                    left: 0;
                    border-radius: 12px;
                    background-color: rgba(141, 151, 165, 0.1);
                }
            }

            &::before {
                content: "";
                position: absolute;
                height: 16px;
                width: 16px;
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: 16px;
                background-color: $icon-button-color;
            }
        }

        .mx_ExtensionsCard_app_pinToggle {
            right: 8px;

            &::before {
                mask-image: url("$(res)/img/element-icons/room/pin-upright.svg");
            }
        }

        .mx_ExtensionsCard_app_options {
            right: 32px; /* 24 + 8 */
            &::before {
                mask-image: url("@vector-im/compound-design-tokens/icons/overflow-horizontal.svg");
            }
        }

        &.mx_ExtensionsCard_Button_pinned {
            &::after {
                opacity: 0.2;
            }

            .mx_ExtensionsCard_app_pinToggle::before {
                background-color: $accent;
            }
        }

        &::before {
            content: unset;
        }

        &::after {
            top: var(--cpd-space-2x); /* re-align based on the height change */
            pointer-events: none; /* pass through to the real button */
        }
    }

    /* Set layout for everyone button */
    a[data-kind="primary"] {
        margin-top: var(--cpd-space-10x);
    }

    .mx_EmptyState::before {
        /* Overlap the Add extensions button */
        top: -76px;
    }
}
