/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_NetworkDropdown_wrapper .mx_ContextualMenu {
    .mx_GenericDropdownMenu_Option {
        &.mx_GenericDropdownMenu_Option--header {
            padding-top: $spacing-12;
            padding-bottom: $spacing-4;
            min-width: 160px;
        }

        &.mx_GenericDropdownMenu_Option--item {
            padding-top: $spacing-4;
            padding-bottom: $spacing-4;

            > .mx_GenericDropdownMenu_Option--label span:first-child {
                font-weight: normal;
            }
        }

        > .mx_GenericDropdownMenu_Option--label {
            flex-direction: row;
            align-items: baseline;
            align-content: baseline;
            color: $primary-content;

            span:not(:first-child) {
                margin-left: $spacing-4;
                color: $secondary-content;
            }
        }
    }

    .mx_GenericDropdownMenu_divider {
        margin-top: $spacing-4;
        margin-bottom: $spacing-4;
    }
}

.mx_NetworkDropdown_addServer {
    font-weight: normal;
    font-size: $font-15px;
}

.mx_NetworkDropdown_removeServer {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: $quinary-content;
    border-radius: 8px;
    text-align: center;
    line-height: 16px;
    color: $secondary-content;
    margin-left: auto;

    &::before {
        background-color: $secondary-content;
        content: "";
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 14px;
        width: inherit;
        height: inherit;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        mask-image: url("@vector-im/compound-design-tokens/icons/close.svg");
    }
}

.mx_NetworkDropdown_dialog .mx_Dialog {
    width: 45vw;
}
