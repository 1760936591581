/*
Copyright 2024 New Vector Ltd.
Copyright 2018 Vector Creations Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_ReplyPreview {
    border: 1px solid $primary-hairline-color;
    border-bottom: none;
    background: $background;
    max-height: 50vh;
    overflow: auto;

    .mx_ReplyPreview_section {
        border-bottom: 1px solid $primary-hairline-color;
        display: flex;
        flex-flow: column;
        row-gap: $spacing-8;
        padding: $spacing-8 $spacing-8 0 0;

        .mx_ReplyPreview_header {
            display: flex;
            justify-content: space-between;
            column-gap: 8px;

            color: $primary-content;
            font-weight: 400;
            opacity: 0.4;

            .mx_ReplyPreview_header_cancel {
                background-color: $primary-content;
                mask-image: url("@vector-im/compound-design-tokens/icons/close.svg");
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: contain;
                width: 20px;
                height: 20px;
                min-width: 20px;
                min-height: 20px;
            }
        }
    }
}

.mx_RoomView_body {
    .mx_ReplyPreview {
        /* Add box-shadow to the reply preview on the main (left) panel only. */
        /* It is not added to the preview on the (right) panel for threads and a chat with a maximized widget. */
        box-shadow: 0px -16px 32px $composer-shadow-color;
        border-radius: 8px 8px 0 0;
    }
}
