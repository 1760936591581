/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_ServerOfflineDialog {
    .mx_ServerOfflineDialog_content {
        padding-right: 85px;
        color: $primary-content;

        hr {
            border-color: $primary-content;
            opacity: 0.1;
            border-bottom: none;
        }

        ul {
            padding: 16px;

            li:nth-child(n + 2) {
                margin-top: 16px;
            }
        }

        .mx_ServerOfflineDialog_content_context {
            .mx_ServerOfflineDialog_content_context_timestamp {
                display: inline-block;
                width: 115px;
                color: $muted-fg-color;
                line-height: 24px; /* same as avatar */
                vertical-align: top;
            }

            .mx_ServerOfflineDialog_content_context_timeline {
                display: inline-block;
                width: calc(100% - 155px); /* 115px timestamp width + 40px right margin */

                .mx_ServerOfflineDialog_content_context_timeline_header {
                    span {
                        margin-left: 8px;
                        vertical-align: middle;
                    }
                }

                .mx_ServerOfflineDialog_content_context_txn {
                    position: relative;
                    margin-top: 8px;

                    .mx_ServerOfflineDialog_content_context_txn_desc {
                        width: calc(100% - 100px); /* 100px is an arbitrary margin for the button */
                    }

                    .mx_AccessibleButton {
                        float: right;
                    }
                }
            }
        }
    }
}
