/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_CountryDropdown .mx_Dropdown_input .mx_Dropdown_option {
    padding: 0 3px;
}

.mx_CountryDropdown .mx_Dropdown_arrow {
    padding-right: 3px;
}

.mx_CountryDropdown_shortOption {
    display: inline-flex;
    align-items: center;
    height: 100%;
}

.mx_CountryDropdown_option {
    display: flex;
    align-items: center;
}
