/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_SuccessDialog {
    text-align: center;

    .mx_Icon {
        margin-bottom: $spacing-16;
    }

    .mx_Dialog_header {
        margin: 0 0 $spacing-16;
        padding: 0;
    }

    .mx_Dialog_title {
        margin: 0;
    }

    .mx_Dialog_content {
        color: $secondary-content;
        margin: 0 0 $spacing-40;
    }

    .mx_Dialog_buttons {
        .mx_Dialog_buttons_row {
            justify-content: center;

            button.mx_Dialog_primary {
                height: 48px;
                min-width: 328px;
            }
        }
    }
}
