/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_WysiwygComposer_Editor_container {
    /* These are set in Javascript */
    --avatar-letter: "";
    --avatar-background: unset;
    --placeholder: "";

    @keyframes visualbell {
        from {
            background-color: $visual-bell-bg-color;
        }
        to {
            background-color: $background;
        }
    }

    .mx_WysiwygComposer_Editor_content {
        line-height: $font-22px;
        white-space: pre-wrap;
        word-wrap: break-word;
        outline: none;
        overflow-x: hidden;

        /* Force caret nodes to be selected in full so that they can be */
        /* navigated through in a single keypress */
        .caretNode {
            user-select: all;
        }

        // we always have a <br/> tag at the end of the html, we need it to be present at first then hide it as soon as
        // we have any other elements
        br:not(:only-child) {
            display: none;
        }

        p {
            margin-top: 0;
            margin-bottom: 0;
            /* this may seem redundant, but we need to handle zero content formatting tags, which occur when we split a
               formatting tag into paragraphs */
            min-height: $font-22px;
        }

        ul,
        ol {
            margin-top: 0;
            margin-bottom: 0;
            padding-inline-start: $spacing-28;
        }

        /* Make list type disc to match rich text editor */
        ul {
            list-style-type: disc;
        }

        blockquote {
            color: #777;
            border-left: 2px solid $blockquote-bar-color;
            border-radius: 2px;
            padding: 0 10px;

            margin-block-start: 0;
            margin-block-end: 0;
            margin-inline-start: 0;
            margin-inline-end: 0;
        }

        > pre {
            font-size: $font-15px;
            line-height: $font-24px;

            margin-top: 0;
            margin-bottom: 0;
            padding: $spacing-8 $spacing-12;

            background-color: $inlinecode-background-color;
            border: 1px solid $inlinecode-border-color;
            border-radius: 2px;
        }

        code:not(pre *) {
            font-family: $monospace-font-family !important;
            background-color: $inlinecode-background-color;
            border: 1px solid $inlinecode-border-color;
            border-radius: 4px;
            padding: $spacing-2;

            &:empty {
                border: unset;
                padding: unset;
            }
        }

        /* this selector represents what will become a pill
        nb despite there being mx_UserPill and mx_RoomPill classes appended to these pills
        in the current composer, there don't appear to be any styles associated with those classes
        in this repo */
        a[data-mention-type] {
            /* combine mx_Pill from _Pill.pcss */
            padding: $font-1px 0.4em;
            line-height: $font-17px;
            border-radius: $font-16px;
            display: inline;
            box-sizing: border-box;
            max-width: 100%;
            overflow: hidden;

            color: var(--cpd-color-text-on-solid-primary);
            background-color: $pill-bg-color;

            /* ...with the overrides from _BasicMessageComposer.pcss */
            user-select: all;
            position: relative;
            cursor: unset; /* We don't want indicate clickability */
            text-overflow: ellipsis;
            white-space: nowrap;

            /* avatar pseudo element */
            &::before {
                /* After consolidation, all of the styling from _Pill.scss was being overridden,
                so take what is in _BasicMessageComposer.pcss as the starting point */
                display: inline-block;
                content: var(--avatar-letter);
                background: var(--avatar-background), $background;

                width: $font-16px;
                min-width: $font-16px; /* ensure the avatar is not compressed */
                height: $font-16px;
                line-height: $font-16px;
                text-align: center;

                /* Get the positioning of the avatar just right for consistency with timeline */
                margin-inline-start: -0.4rem;
                margin-inline-end: 0.24rem;
                vertical-align: 0.12rem;

                background-repeat: no-repeat;
                background-size: $font-16px;
                border-radius: $font-16px;

                color: var(--avatar-color, $avatar-initial-color);
                font-weight: bold;
                font-size: $font-10-4px;
            }
        }
    }

    .mx_WysiwygComposer_Editor_content_placeholder::before {
        content: var(--placeholder);
        width: 0;
        height: 0;
        overflow: visible;
        display: inline-block;
        pointer-events: none;
        white-space: nowrap;
        color: var(--cpd-color-text-secondary);
    }
}

.mx_WysiwygComposer_AutoCompleteWrapper {
    position: relative;

    /* Due to the fact that editing a message now has a larger amount of grey
    colour above it (due to the rich text buttons above the composer), we need
    to give the autocomplete a bit more visual separation by using a border.
    */
    > .mx_Autocomplete {
        border: 1px solid $quinary-content;
        border-radius: 8px;
    }
}
