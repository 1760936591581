/*
Copyright 2024 New Vector Ltd.
Copyright 2024 The Matrix.org Foundation C.I.C.
Copyright 2019 New Vector Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_PreferencesUserSettingsTab_section_hint {
    font: var(--cpd-font-body-sm-regular);
    color: var(--cpd-color-text-secondary);
}
