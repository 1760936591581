/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_ErrorMessage {
    align-items: center;
    color: $alert;
    display: flex;
    font-size: $font-12px;
    gap: $spacing-8;
    line-height: 1.2em;
    min-height: 2.4em;
}
