/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_UserOnboardingButton {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: stretch;
    border-radius: 8px;
    margin: $spacing-8 $spacing-8 0;
    padding: $spacing-12;

    &.mx_UserOnboardingButton_selected,
    &:hover,
    &:focus-within {
        background-color: $panel-actions;
    }

    .mx_UserOnboardingButton_content {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;

        .mx_Heading_h4 {
            margin-right: auto;
            font: var(--cpd-font-body-md-regular);
            color: $primary-content;
        }

        .mx_UserOnboardingButton_percentage {
            font-size: $font-12px;
            color: $secondary-content;
        }

        .mx_UserOnboardingButton_close {
            position: relative;
            box-sizing: border-box;
            width: 14px;
            height: 14px;
            border-radius: 7px;
            border: 1px solid $secondary-content;
            flex-shrink: 0;

            &::before {
                background-color: $secondary-content;
                content: "";
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: 12px;
                width: inherit;
                height: inherit;
                position: absolute;
                left: -1px;
                top: -1px;
                mask-image: url("@vector-im/compound-design-tokens/icons/close.svg");
            }
        }
    }

    .mx_ProgressBar {
        width: auto;
        margin-top: $spacing-8;
        background: $background;
    }

    &.mx_UserOnboardingButton_completed .mx_ProgressBar {
        display: none;
    }
}
