/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_SpaceCreateMenu_wrapper {
    /* background blur everything except SpacePanel */
    .mx_ContextualMenu_background {
        background-color: $dialog-backdrop-color;
        opacity: 0.6;
        left: 68px;
    }

    .mx_ContextualMenu {
        padding: 24px;
        width: 480px;
        box-sizing: border-box;
        background-color: $background;
        position: relative;

        > div {
            > h2 {
                font-weight: var(--cpd-font-weight-semibold);
                font-size: $font-18px;
                margin-top: 4px;
            }

            > p {
                font-size: $font-15px;
                color: $secondary-content;
            }
        }

        .mx_SpaceCreateMenuType {
            @mixin SpacePillButton;
        }

        .mx_SpaceCreateMenuType_public::before {
            mask-image: url("@vector-im/compound-design-tokens/icons/public.svg");
        }

        .mx_SpaceCreateMenuType_private::before {
            mask-image: url("@vector-im/compound-design-tokens/icons/lock-solid.svg");
        }

        .mx_SpaceCreateMenu_back {
            width: 28px;
            height: 28px;
            position: relative;
            background-color: $panel-actions;
            border-radius: 14px;
            margin-bottom: 12px;

            &::before {
                content: "";
                position: absolute;
                height: 28px;
                width: 28px;
                top: 0;
                left: 0;
                background-color: $tertiary-content;
                transform: rotate(90deg);
                mask-repeat: no-repeat;
                mask-position: 2px 3px;
                mask-size: 24px;
                mask-image: url("@vector-im/compound-design-tokens/icons/chevron-down.svg");
            }
        }

        .mx_AccessibleButton_kind_primary {
            padding: 8px 22px;
            margin-left: auto;
            display: block;
            width: min-content;
        }

        .mx_AccessibleButton_disabled {
            cursor: not-allowed;
        }
    }
}
