/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.
Copyright 2019 New Vector Ltd
Copyright 2017 Travis Ralston

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_TabbedView {
    margin: 0;
    padding: 0 0 0 var(--cpd-space-8x);
    display: flex;
    flex-direction: column;
    inset: 0;
    margin-top: 8px;
}

.mx_TabbedView_tabsOnLeft {
    flex-direction: column;
    position: absolute;

    .mx_TabbedView_tabLabels {
        width: 220px;
        max-width: 220px;
        position: fixed;
        margin: 0; /* Remove the default value */
        padding: 0; /* Remove the default value */
    }

    .mx_TabbedView_tabPanel {
        margin-left: 280px; /* 220px sidebar + 60px padding */
        flex-direction: column;
    }

    .mx_TabbedView_tabLabel:hover,
    .mx_TabbedView_tabLabel_active {
        color: $tab-label-active-fg-color;

        .mx_TabbedView_maskedIcon::before {
            background-color: var(--cpd-color-icon-primary);
        }
    }

    .mx_TabbedView_tabLabel_active {
        background-color: var(--cpd-color-bg-subtle-secondary);
    }

    .mx_TabbedView_maskedIcon {
        width: 20px;
        height: 20px;
        margin-right: var(--cpd-space-3x);
    }

    .mx_TabbedView_maskedIcon::before {
        mask-size: 20px;
        width: 20px;
        height: 20px;
        transition: background-color 0.1s;
    }
}

.mx_TabbedView_tabsOnTop {
    flex-direction: column;

    .mx_TabbedView_tabLabels {
        display: flex;
        margin-bottom: 8px;
    }

    .mx_TabbedView_tabLabel {
        padding-left: 0px;
        padding-right: 52px;

        .mx_TabbedView_tabLabel_text {
            font-size: 15px;
            color: $tertiary-content;
        }
    }

    .mx_TabbedView_tabPanel {
        flex-direction: row;
    }

    .mx_TabbedView_tabLabel_active {
        color: $accent;
        .mx_TabbedView_tabLabel_text {
            color: $accent;
        }
    }

    .mx_TabbedView_tabLabel_active .mx_TabbedView_maskedIcon::before {
        background-color: $accent;
    }

    .mx_TabbedView_maskedIcon {
        width: 22px;
        height: 22px;
        margin-left: 0px;
        margin-right: 8px;
    }

    .mx_TabbedView_maskedIcon::before {
        mask-size: 22px;
        width: inherit;
        height: inherit;
    }
}

.mx_TabbedView_tabLabels {
    color: $tab-label-fg-color;
}

.mx_TabbedView_tabLabel {
    display: flex;
    align-items: center;
    vertical-align: text-top;
    cursor: pointer;
    padding-block: var(--cpd-space-2x);
    padding-inline: var(--cpd-space-3x) var(--cpd-space-4x);
    box-sizing: border-box;
    min-block-size: 40px;
    min-inline-size: 40px;
    border-radius: 24px;
    font: var(--cpd-font-body-md-medium);
    position: relative;
    transition:
        color 0.1s,
        background-color 0.1s;

    svg {
        width: 20px;
        height: 20px;
        margin-right: var(--cpd-space-3x);
    }
}

.mx_TabbedView_maskedIcon {
    display: inline-block;
}

.mx_TabbedView_maskedIcon::before {
    display: inline-block;
    background-color: var(--cpd-color-icon-secondary);
    mask-repeat: no-repeat;
    mask-position: center;
    content: "";
}

.mx_TabbedView_tabLabel_text {
    vertical-align: middle;
}

.mx_TabbedView_tabPanel {
    flex-grow: 1;
    display: flex;
    min-height: 0; /* firefox */
}

.mx_TabbedView_tabPanelContent {
    flex-grow: 1;
    overflow: auto;
    min-height: 0; /* firefox */
}

/* Hide the labels on tabs, showing only the icons, on narrow viewports. */
@media (max-width: 1024px) {
    .mx_TabbedView_tabsOnLeft.mx_TabbedView_responsive {
        .mx_TabbedView_tabLabel_text {
            display: none;
        }
        .mx_TabbedView_tabPanel {
            margin-left: 72px; /* 40px sidebar + 32px padding */
        }
        .mx_TabbedView_maskedIcon {
            margin-right: auto;
            margin-left: auto;
        }
        .mx_TabbedView_tabLabels {
            width: auto;
        }
        .mx_TabbedView_tabLabel {
            padding-inline: 0 0;
            justify-content: center;
            svg {
                margin-right: 0;
            }
        }
    }
}
