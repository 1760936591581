/*
Copyright 2024 New Vector Ltd.
Copyright 2021 Šimon Brandner <simon.bra.ag@gmail.com>
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_IncomingLegacyCallToast {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    pointer-events: initial; /* restore pointer events so the user can accept/decline */

    .mx_IncomingLegacyCallToast_content {
        display: flex;
        flex-direction: column;
        margin-left: 8px;

        .mx_LegacyCallEvent_caller {
            font-weight: bold;
            font-size: $font-15px;
            line-height: $font-18px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            margin-top: 2px;
            margin-right: 6px;

            max-width: 200px;
        }

        .mx_LegacyCallEvent_type {
            font-size: $font-12px;
            line-height: $font-15px;
            color: $tertiary-content;

            margin-top: 4px;
            margin-bottom: 6px;

            display: flex;
            flex-direction: row;
            align-items: center;

            .mx_LegacyCallEvent_type_icon {
                height: 16px;
                width: 16px;
                margin-right: 6px;

                &::before {
                    content: "";
                    position: absolute;
                    height: inherit;
                    width: inherit;
                    background-color: $tertiary-content;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                }
            }
        }

        &.mx_IncomingLegacyCallToast_content_voice {
            .mx_LegacyCallEvent_type .mx_LegacyCallEvent_type_icon::before,
            .mx_IncomingLegacyCallToast_buttons .mx_IncomingLegacyCallToast_button_accept span::before {
                mask-image: url("$(res)/img/element-icons/call/voice-call.svg");
            }
        }

        &.mx_IncomingLegacyCallToast_content_video {
            .mx_LegacyCallEvent_type .mx_LegacyCallEvent_type_icon::before,
            .mx_IncomingLegacyCallToast_buttons .mx_IncomingLegacyCallToast_button_accept span::before {
                mask-image: url("$(res)/img/element-icons/call/video-call.svg");
            }
        }

        .mx_IncomingLegacyCallToast_buttons {
            margin-top: 8px;
            display: flex;
            flex-direction: row;
            gap: 12px;

            .mx_IncomingLegacyCallToast_button {
                @mixin LegacyCallButton;
                padding: 0px 8px;
                flex-shrink: 0;
                flex-grow: 1;
                font-size: $font-15px;

                span {
                    padding: 8px 0;
                }

                &.mx_IncomingLegacyCallToast_button_accept span::before {
                    mask-size: 13px;
                    width: 13px;
                    height: 13px;
                }

                &.mx_IncomingLegacyCallToast_button_decline span::before {
                    mask-image: url("$(res)/img/element-icons/call/hangup.svg");
                    mask-size: 16px;
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    .mx_IncomingLegacyCallToast_iconButton {
        display: flex;
        height: 20px;
        width: 20px;

        &::before {
            content: "";

            height: inherit;
            width: inherit;
            background-color: $tertiary-content;
            mask-repeat: no-repeat;
            mask-size: contain;
            mask-position: center;
        }
    }

    .mx_IncomingLegacyCallToast_silence::before {
        mask-image: url("$(res)/img/voip/silence.svg");
    }

    .mx_IncomingLegacyCallToast_unSilence::before {
        mask-image: url("$(res)/img/voip/un-silence.svg");
    }
}
