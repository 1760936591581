/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_NotificationSettingsTab_notificationsSection {
    width: 360px;

    .mx_StyledRadioButton {
        flex-direction: row-reverse;
        color: $primary-content;
        font-size: $font-15px;
        line-height: $font-18px;
        font-weight: var(--cpd-font-weight-semibold);
        margin-top: 16px;
        position: relative;
        padding-left: 8px;
        align-items: center;

        &::before {
            content: "";
            position: absolute;
            height: 24px;
            width: 24px;
            left: 0;
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: contain;
            background-color: $secondary-content;
        }

        input + div {
            margin-top: 8px;
        }

        .mx_NotificationSettingsTab_microCopy {
            color: $secondary-content;
            font-weight: normal;
            font-size: $font-12px;
            line-height: $font-15px;
            margin-right: 32px;
        }
    }

    .mx_NotificationSettingsTab_defaultEntry::before {
        mask-image: url("$(res)/img/element-icons/notifications.svg");
    }

    .mx_NotificationSettingsTab_allMessagesEntry::before {
        mask-image: url("$(res)/img/element-icons/roomlist/notifications-default.svg");
    }

    .mx_NotificationSettingsTab_mentionsKeywordsEntry::before {
        mask-image: url("$(res)/img/element-icons/roomlist/notifications-dm.svg");
    }

    .mx_NotificationSettingsTab_noneEntry::before {
        mask-image: url("$(res)/img/element-icons/roomlist/notifications-off.svg");
    }
}

input[type="file"].mx_NotificationSound_soundUpload {
    display: none;
}
