/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_InfoTooltip_icon {
    width: 16px;
    height: 16px;
    display: inline-block;
}

.mx_InfoTooltip_icon::before {
    display: inline-block;
    background-color: $muted-fg-color;
    mask-repeat: no-repeat;
    mask-size: 16px;
    width: 16px;
    height: 16px;
    mask-position: center;
    content: "";
    vertical-align: middle;
}

.mx_InfoTooltip_icon_info::before {
    mask-image: url("@vector-im/compound-design-tokens/icons/info.svg");
}

.mx_InfoTooltip_icon_warning::before {
    mask-image: url("@vector-im/compound-design-tokens/icons/error.svg");
}
