/*
Copyright 2024 New Vector Ltd.
Copyright 2020, 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_Field.mx_AppearanceUserSettingsTab_checkboxControlledField {
    width: 256px;
    /* matches checkbox box + padding to align with checkbox label */
    margin-inline-start: calc($font-16px + 10px);
}
