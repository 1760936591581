/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_PowerSelector {
    width: 100%;
}

.mx_PowerSelector .mx_Field select,
.mx_PowerSelector .mx_Field input {
    box-sizing: border-box;
}
