/*
Copyright 2024 New Vector Ltd.
Copyright 2016 OpenMarket Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_PresenceLabel {
    font-size: $font-11px;
    opacity: 0.5;
}

.mx_PresenceLabel_online {
    color: var(--cpd-color-text-success-primary);
}
