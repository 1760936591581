/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_NewRoomIntro {
    margin: 40px 0 48px 64px;

    .mx_MiniAvatarUploader_hasAvatar:not(.mx_MiniAvatarUploader_busy):not(:hover) {
        .mx_MiniAvatarUploader_indicator {
            display: none;
        }
    }

    .mx_NewRoomIntro_buttons {
        margin-top: 28px;
        display: flex;
        flex-flow: wrap;
        gap: 14px 12px;

        .mx_AccessibleButton {
            line-height: $font-24px;
            display: inline-block;

            &:not(.mx_AccessibleButton_kind_primary_outline)::before {
                content: "";
                display: inline-block;
                background-color: $button-fg-color;
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: 20px;
                width: 20px;
                height: 20px;
                margin-right: 5px;
                vertical-align: text-bottom;
            }
        }

        .mx_NewRoomIntro_inviteButton::before {
            mask-image: url("$(res)/img/element-icons/room/invite.svg");
        }
    }

    > h2 {
        margin-top: 24px;
        font-size: $font-24px;
        font-weight: var(--cpd-font-weight-semibold);
    }

    > p {
        margin: 0;
        font-size: $font-15px;
        color: $secondary-content;
    }
}
