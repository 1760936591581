/*
Copyright 2024 New Vector Ltd.
Copyright 2021 Robin Townsend <robin@robin.town>

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_EventTileBubble.mx_HistoryTile {
    margin: var(--EventTileBubble_margin-block) auto;

    &::before {
        background-color: $header-panel-text-primary-color;
        mask-image: url("$(res)/img/element-icons/hide.svg");
    }
}
