/*
Copyright 2024 New Vector Ltd.
Copyright 2023 Nordeck IT + Consulting GmbH

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_PeopleRoomSettingsTab_knock {
    display: flex;
    margin-top: var(--cpd-space-2x);
}

.mx_PeopleRoomSettingsTab_content {
    flex-grow: 1;
    margin: 0 var(--cpd-space-4x);
}

.mx_PeopleRoomSettingsTab_avatar {
    align-self: flex-start;
    flex-shrink: 0;
}

.mx_PeopleRoomSettingsTab_name {
    font-weight: var(--cpd-font-weight-semibold);
}

.mx_PeopleRoomSettingsTab_timestamp {
    color: $secondary-content;
    margin-left: var(--cpd-space-1x);
}

.mx_PeopleRoomSettingsTab_userId {
    color: $secondary-content;
    display: block;
    font-size: var(--cpd-font-size-body-sm);
}

.mx_PeopleRoomSettingsTab_seeMoreOrLess {
    margin: var(--cpd-space-3x) 0 0;
}

.mx_PeopleRoomSettingsTab_action {
    flex-shrink: 0;

    + .mx_PeopleRoomSettingsTab_action {
        margin-left: var(--cpd-space-3x);
    }
}

.mx_PeopleRoomSettingsTab_paragraph {
    margin: 0;
}
