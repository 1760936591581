/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_VoiceBroadcastControl {
    align-items: center;
    background-color: $background;
    border-radius: 50%;
    color: $secondary-content;
    display: flex;
    flex: 0 0 32px;
    height: 32px;
    justify-content: center;
    width: 32px;
}

.mx_VoiceBroadcastControl-recording {
    color: $alert;
}

.mx_VoiceBroadcastControl-play .mx_Icon {
    left: 1px;
    position: relative;
}
