/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_ServerPicker {
    margin-bottom: 14px;
    padding-bottom: $spacing-16;
    border-bottom: 1px solid rgba(141, 151, 165, 0.2);
    display: grid;
    grid-template-columns: auto min-content;
    grid-template-rows: auto auto auto;
    font: var(--cpd-font-body-md-regular);

    > h2 {
        font-weight: var(--cpd-font-weight-semibold);
        margin: 0 0 20px;
        grid-column: 1;
        grid-row: 1;
    }

    .mx_ServerPicker_help {
        width: 20px;
        height: 20px;
        background-color: $icon-button-color;
        border-radius: 10px;
        grid-column: 2;
        grid-row: 1;
        margin-left: auto;
        text-align: center;
        color: #ffffff;
        font-size: 16px;
        position: relative;

        &::before {
            content: "";
            width: 24px;
            height: 24px;
            position: absolute;
            top: -2px;
            left: -2px;
            mask-position: center;
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-image: url("$(res)/img/element-icons/i.svg");
            background: #ffffff;
        }
    }

    .mx_ServerPicker_server {
        color: $authpage-primary-color;
        grid-column: 1;
        grid-row: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 8px;
    }

    .mx_ServerPicker_change {
        grid-column: 2;
        grid-row: 2;
    }

    .mx_ServerPicker_desc {
        margin-top: 4px;
        color: $tertiary-content;
        grid-column: 1 / 2;
        grid-row: 3;
    }
}

.mx_ServerPicker_helpDialog {
    .mx_Dialog_content {
        width: 456px;
    }
}
