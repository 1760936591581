/*
Copyright 2018-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_AuxPanel {
    min-width: 0px;
    width: 100%;
    margin: 0px auto;

    overflow: auto;
}
