/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_VoiceBroadcastRecordingConnectionError {
    align-items: center;
    color: $alert;
    display: flex;
    gap: $spacing-12;

    svg path {
        fill: $alert;
    }
}
