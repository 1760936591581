/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_DeviceDetailHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.mx_DeviceDetailHeading_renameCta {
    flex-shrink: 0;
}

.mx_DeviceDetailHeading_renameForm {
    display: grid;
    grid-gap: $spacing-16;
    justify-content: left;
    grid-template-columns: 100%;
}

.mx_DeviceDetailHeading_renameFormButtons {
    display: flex;
    flex-direction: row;
    gap: $spacing-8;

    .mx_Spinner {
        width: auto;
        flex-grow: 0;
    }
}

.mx_DeviceDetailHeading_renameFormInput {
    /* override field styles */
    margin: 0 0 $spacing-4 0 !important;
}

.mx_DeviceDetailHeading_renameFormHeading {
    margin: 0;
}

.mx_DeviceDetailHeading_renameFormError {
    color: $alert;
    padding-right: $spacing-4;
    display: block;
}
