/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_SetupEncryptionBody_reset {
    color: $light-fg-color;
    margin-top: $font-14px;

    .mx_SetupEncryptionBody_reset_link {
        &.mx_AccessibleButton_kind_link_inline {
            color: $alert;
        }
    }
}
