/*
 * Copyright 2024 New Vector Ltd.
 *
 * SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
 * Please see LICENSE files in the repository root for full details.
 *
 */

.mx_PinnedMessageBadge {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--cpd-space-1x);

    padding: var(--cpd-space-1x) var(--cpd-space-3x) var(--cpd-space-1x) var(--cpd-space-1x);
    font: var(--cpd-font-body-xs-medium);
    background-color: var(--cpd-color-alpha-gray-200);
    color: var(--cpd-color-text-secondary);

    border-radius: 99px;
    border: 1px solid var(--cpd-color-alpha-gray-400);

    svg {
        fill: var(--cpd-color-icon-secondary);
    }
}
