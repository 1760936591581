/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_LiveBadge {
    align-items: center;
    background-color: $alert;
    border-radius: 2px;
    color: $live-badge-color;
    display: inline-flex;
    font-size: $font-12px;
    font-weight: var(--cpd-font-weight-semibold);
    gap: $spacing-4;
    padding: 2px 4px;
}

.mx_LiveBadge--grey {
    background-color: $quaternary-content;
}
