/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_RegistrationEmailPromptDialog {
    width: 417px;

    .mx_Dialog_content {
        margin-bottom: 24px;
        color: $tertiary-content;
    }

    .mx_Dialog_primary {
        width: 100%;
    }
}
