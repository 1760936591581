/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_AuthHeader {
    display: flex;
    flex-direction: column;
    width: 206px;
    padding: 25px 25px;
    box-sizing: border-box;
}

@media only screen and (max-width: 480px) {
    .mx_AuthHeader {
        display: none;
    }
}
