/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_RolesRoomSettingsTab_bannedList {
    margin-bottom: 0;
}

.mx_RolesRoomSettingsTab_unbanBtn {
    margin-right: 10px;
    margin-bottom: 5px;
}
